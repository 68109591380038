.product {
  padding: 54px 0 44px;

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 470px;
    column-gap: 16px;

    @media screen and (max-width: 1440px) {
      display: block;
    }
  }

  &__carousel {
    height: max-content;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      margin: auto 0;
    }
  }

  &__img {
    max-height: 500px;
    width: 100%;
    object-fit: contain;

  }

  &__info {
    border: 1px solid #e7e7e7;
    border-radius: 7px;
    box-shadow: #22222210 0px 4px 14px;
    display: flex;
    flex-direction: column;
    height: fit-content;

    &-top,
    &-middle,
    &-bottom {
      padding: 15px 20px;
    }

    &-top,
    &-middle {
      border-bottom: 1px solid #e9e9e9;
    }

    &-top{
      position: relative;
    }

    &-bottom {
      display: flex;
      justify-content: space-between;
    }
  }

  &__title {
    font-size: 30px;
  }

  &__block {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 16px;
  }

  &__location {
    text-decoration: underline;
    font-size: 16px;
  }

  &__price {
    display: flex;
    align-items: flex-start;
    gap: 4px;
  }

  &__newPrice {
    font-weight: 500;
    font-size: 20px;
    color: #ee1212;
  }

  &__oldPrice {
    font-size: 14px;
    text-decoration: line-through #ee1212;
  }

  &__price-label {
    font-size: 14px;
    font-weight: 500;
    align-self: flex-end;
    margin-left: 4px;
  }

  &__description {
    opacity: 0.8;
    margin-bottom: 16px;
  }

  &__total-label {
    font-weight: 500;
  }

  &__total-price {
    font-weight: 500;
  }


  &__reviews {
    padding: 54px 0 44px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px 16px;
  }

  @media screen and (max-width: 768px) {
    &__reviews {
      grid-template-columns: 1fr;
      gap: 16px 12px;
      padding: 24px 0 16px;
    }
  }

}