.animated-gradient {
  animation: gradient-animation 2s linear infinite;
}

@keyframes gradient-animation {
  0% {
    transform: translateX(-30%);
  }

  100% {
    transform: translateX(100%);
  }

}