.container {
  width: 75%;
  max-width: 1040px;
  margin: 0 auto;

  @media screen and (max-width: 1040px) {
    width: 80%;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
  }
}