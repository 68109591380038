.about {
  padding: 54px 0 44px;
  background: #333333;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    gap: 10%;
  }

  &__title {
    color: white;
    font-weight: 500;
  }

  &__content {

    p {
      text-indent: 20px;
      color: white;
      margin-bottom: 6px;
    }
  }


  &__icons-list {
    display: flex;
    flex-direction: column;
  }

  &__icons-item {
    display: grid;
    grid-template-columns: 24px 1fr;
    align-items: center;
    gap: 10px;
    margin-bottom: 12px;

    &:hover {

      .about__icons-text,
      .about__icon {
        color: #FF5A5F;
      }
    }
  }

  &__icons-text {
    color: white;
  }

  &__icon {
    font-size: 24px;
    color: white;
    cursor: pointer;
  }

  // &__instagram {
  //   &:hover {
  //     color: #f7c729;
  //   }
  // }

  // &__facebook {
  //   &:hover {
  //     color: #4167B1;
  //   }
  // }

  // &__tiktok {
  //   &:hover {
  //     color: #01F1E8;
  //   }
  // }

  // &__airbnb {
  //   &:hover {
  //     color: #FF5A5F;
  //   }
  // }

  @media screen and (max-width: 768px) {
    &__wrapper {
      flex-direction: column;
    }

    &__title {
      font-size: 36px;
    }
  }

  &__content {
    margin-bottom: 36px;
  }

  &__icons-list {
    align-items: center;
  }
}