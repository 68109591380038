@import '../../variables.scss';

.products {
  padding: 54px 0 44px;
  // background: #F7FAFC;
  // background: linear-gradient(70deg, #364170 0%, #364170 max(12.5%, (75% - 1040px)), #46507D calc(max(12.5%, (75% - 1040px)) + 195px), #6b75a1 100%);

  &__wrapper {
    // padding: 54px 24px;
    // background: $secondaryColor;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 16px;
  }

  h2{
    font-weight: 500;
  }


  @media screen and (max-width: 1040px) {
    &__wrapper {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @media screen and (max-width: 768px) {
    &__wrapper {
      grid-template-columns: 1fr 1fr;
    }

    h2{
      font-size: 36px;
    }
  }

  @media screen and (max-width: 440px) {
    &__wrapper {
      grid-template-columns: 1fr;
    }
  }
}

.productItem {
  cursor: pointer;
  position: relative;
  background: $tertiartColor;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .05);
  padding-bottom: 20px;

  // &::after {
  //   content: '';
  //   position: absolute;
  //   bottom: 15px;
  //   left: 5%;
  //   display: block;
  //   height: 21px;
  //   width: 0;
  //   background: #ddda19;
  //   margin: 0 auto;
  //   border-radius: 20px;
  //   transition: all .44s ease;
  // }


  &__top {
    width: 100%;
    height: 200px;
    margin-bottom: 9px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__bottom {
    color: $tertiartColor;
    padding: 0 16px 16px 16px;
    margin-bottom: 5px;
  }

  &__location {
    display: flex;
    align-items: center;
    gap: 4px;
    opacity: 0.8;
    margin-bottom: 16px;

    &-text {
      font-size: 14px;
      color: #3a3a3a;
    }
  }

  &__title {
    font-weight: 500;
    margin-bottom: 12px;
    // line-height: 120%;
    text-transform: uppercase;
  }

  &__price {
    display: flex;
    align-items: flex-end;
    gap: 4px;

    &-text {
      font-size: 14px;
    }

    &-number {
      font-size: 16px;
      font-weight: 500;
    }
  }

  &__description {
    margin-bottom: 9px;
    font-size: 14px;
  }

  &__label {
    color: $tertiartColor;
    font-weight: 500;
    text-align: center;
    position: absolute;
    bottom: 16px;
    left: 0;
    width: 100%;
    z-index: 10;
  }

  &:hover {
    box-shadow: 0 0 11px 0 rgba(0, 0, 0, .17);

    .productItem__title {
      // color: $secondaryColor;
      // color: #ddda19;
      text-decoration: underline;
    }

    // &::after {
    //   width: 90%;
    // }

  }
}