.PhoneInputCountryIcon {
  width: 40px;
  height: 40px;
}

.PhoneInputInput {

  width: 100%;
  height: 40px;
  min-width: 0px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  border: 1px solid rgb(226, 232, 240);
  border-radius: 5px;
  padding: 0 16px;

  &:focus {
    outline: none;
    border-color: #38b2ac;
    box-shadow: 0 0 0 3px rgba(56, 178, 172, 0.4);
  }
}