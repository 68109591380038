@import './variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Bacasime+Antique&family=Commissioner:wght@400;500;600&family=Inter:wght@700&family=Titillium+Web:wght@300;400&display=swap');
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  // background: #f1f2f8;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
  letter-spacing: 0.12rem;
}

h1,
h2,
h3,
h4,
p,
a {
  color: $primaryColor;
  line-height: 130%;
}

p {
  font-size: 18px;
}

a {
  text-decoration: none;
}

h2 {
  font-size: 44px;
  margin: 12px 0 24px;
}


// animation
.tracking-in-expand-fwd-bottom {
  -webkit-animation: tracking-in-expand-fwd-bottom .8s cubic-bezier(.215, .61, .355, 1.000) both;
  animation: tracking-in-expand-fwd-bottom .8s cubic-bezier(.215, .61, .355, 1.000) both
}

/* ----------------------------------------------
 * Generated by Animista on 2023-7-9 22:27:49
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes tracking-in-expand-fwd-bottom {
  0% {
    letter-spacing: -.5em;
    -webkit-transform: translateZ(-40px) translateY(0px);
    transform: translateZ(-40px) translateY(0px);
    opacity: 0
  }

  40% {
    opacity: .6
  }

  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1
  }
}

@keyframes tracking-in-expand-fwd-bottom {
  0% {
    letter-spacing: -.5em;
    -webkit-transform: translateZ(-40px) translateY(0px);
    transform: translateZ(-40px) translateY(0px);
    opacity: 0
  }

  40% {
    opacity: .6
  }

  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1
  }
}