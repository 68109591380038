.calendar {

  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: space-between;
    max-width: 450px;
    // gap: 16px;
  }

  &__start,
  &__end {
    padding: 7px 12px;
    font-size: 16px;
    border: 1px solid #46507D;
    border-radius: 7px;

    &:focus {
      outline: 2px solid #46507D;

    }
  }

  @media screen and (max-width: 768px) {

    &__start {
      margin-bottom: 16px;
    }
  }
}

.react-datepicker-popper {
  z-index: 1001 !important;
}