.picture {
  // background: url('https://novostroycity.ru/uploads/journal_entries/1602508947.jpg') no-repeat center center fixed;
  background: linear-gradient(to left, #0000001a, #0000003b 100%), url('../../assets/images/pic.jpg') no-repeat center center fixed;
  // background: url('https://res.cloudinary.com/g5-assets-cld/image/upload/x_0,y_326,h_2638,w_4397,c_crop/q_auto,f_auto,fl_lossy,g_center,h_1200,w_2000/g5/g5-c-5g13txeqo-mark-taylor-companies-client/g5-cl-1ilppjz7kx-aviva/uploads/18-037-24_Fire_Pit_Area_efyaf4.jpg') no-repeat center center fixed;
  // background: linear-gradient(70deg, #36417070 0%, #36417070 max(12.5%, (75% - 1040px)), #46507D70 calc(max(12.5%, (75% - 1040px)) + 195px), #6b75a170 100%), url('https://res.cloudinary.com/g5-assets-cld/image/upload/x_0,y_326,h_2638,w_4397,c_crop/q_auto,f_auto,fl_lossy,g_center,h_1200,w_2000/g5/g5-c-5g13txeqo-mark-taylor-companies-client/g5-cl-1ilppjz7kx-aviva/uploads/18-037-24_Fire_Pit_Area_efyaf4.jpg') no-repeat center center fixed;
  background-size: cover;
  height: 780px;
  display: flex;
  align-items: center;



  &__wrapper {
    position: relative;
    max-width: 750px;
    // padding: 24px 30px 34px;
    // border: 4px white solid;
    // box-shadow: 0px 0px 10px #0f0f0f62;

    // &-blur {
    //   display: block;
    //   // filter: blur(4px);
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background: #0f0f0f62;
    //   z-index: 1;
    // }
  }

  &__wrapper-content {
    position: relative;
    z-index: 2;
  }

  &__title {
    font-weight: 500;
    font-size: 54px;
    color: white;
    text-shadow: black 0 0 25px;
    letter-spacing: 0.2rem;

    margin-bottom: 16px;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 28px;
    text-shadow: black 0 0 25px;
    color: white;
    // text-shadow: black 0 0 25px;
    font-family: 'Bacasime Antique', serif;
    letter-spacing: 0.2rem;
  }

  @media screen and (max-width: 1040px) {
    height: 600px;

    &__wrapper {
      max-width: 550px;
    }

    &__title {
      font-size: 44px;
    }

    &__subtitle {
      font-size: 24px;
    }
  }

  @media screen and (max-width: 768px) {
    height: 500px;

    &__wrapper {
      max-width: 450px;
    }

    &__title {
      font-size: 36px;
    }

    &__subtitle {
      font-size: 20px;
    }
  }
}

@supports (-webkit-touch-callout: none) {

  // @media screen and (max-width: 1180px) {
  .picture {
    background: linear-gradient(to left, #0000001a, #0000003b 100%), url('../../assets/images/pic.jpg') no-repeat center center;
    background-size: cover;
  }

  // }
}

// or
// @supports (background-position: fixed) {
//   .picture {
//     background-attachment: fixed;
//   }
// }