@import '../../variables.scss';

.header {
  // background: #3C4675;
  // background: linear-gradient(to left,  #828dbb, #2d3869);
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(70deg, #364170 0%, #364170 max(12.5%, (75% - 1040px)), #46507D calc(max(12.5%, (75% - 1040px)) + 195px), #6b75a1 100%);

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 105px;
  }

  &__logo {
    display: block;
    height: 100%;

    img {
      height: 100%;
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    gap: 16px;

    &-item {
      color: white;
      font-weight: 500;
      font-size: 18px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  
  @media screen and (max-width: 768px) {
    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 75px;
    } 

    &__menu {

      &-item {
        font-size: 16px;
      }
    }
  }
}

.header-space {
  height: 105px;

  @media screen and (max-width: 768px) {
    height: 75px;
  }
}